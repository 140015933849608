import {
  JobSearchGrades,
  JobSearchRootSpecialitiesV2,
  JobSearchSpecialities,
  JobSearchSubSpecialities,
} from '@/types/jobs';
import { ReactElement } from 'react';

import { MenuItem } from '@material-ui/core';

export const DEFAULT_RADIUS = '50';
const DISTANCES = ['25', '50', '75', '100', '150'];

const radiusOptions = DISTANCES.map(radius => (
  <MenuItem value={radius} key={radius}>
    {radius} km
  </MenuItem>
));

export const getRadiusOptions = (): ReactElement[] => {
  return radiusOptions;
};

export const getGradeOptions = (grades: JobSearchGrades | null): ReactElement[] => {
  const options = [
    <MenuItem value="none" key="none" data-testid={'none'}>
      <em>None</em>
    </MenuItem>,
  ];

  if (grades) {
    grades.forEach(
      grade =>
        grade &&
        options.push(
          <MenuItem value={grade.value} key={grade.key} data-testid={grade.key}>
            {grade.value}
          </MenuItem>,
        ),
    );
  }

  return options;
};

export const getSpecialityOptions = (
  selectedGrade: string | undefined,
  specialities: JobSearchSpecialities,
): ReactElement[] => {
  const options = [
    <MenuItem value="none" key="none" data-testid={'none'}>
      <em>{selectedGrade ? 'None' : 'Please select a grade'}</em>
    </MenuItem>,
  ];

  selectedGrade &&
    specialities[selectedGrade] &&
    specialities[selectedGrade]?.forEach(i =>
      options.push(
        <MenuItem value={i.value} key={i.key} data-testid={i.key}>
          {i.value}
        </MenuItem>,
      ),
    );

  return options;
};

export const getRootSpecialityOptions = (
  selectedGrade: string | undefined,
  specialities: JobSearchRootSpecialitiesV2,
): ReactElement[] => {
  const options = [
    <MenuItem value="none" key="none" data-testid={'none'}>
      <em>{selectedGrade ? 'None' : 'Please select a grade'}</em>
    </MenuItem>,
  ];

  selectedGrade &&
    specialities[selectedGrade] &&
    specialities[selectedGrade]?.forEach(i =>
      options.push(
        <MenuItem value={i.value} key={i.key} data-testid={i.key}>
          {i.value}
        </MenuItem>,
      ),
    );

  return options;
};

export const getSubSpecialityOptions = (
  speciality: string | undefined,
  subSpecialities: JobSearchSubSpecialities,
): ReactElement[] => {
  const options = [
    <MenuItem value="none" key="none" data-testid={'none'}>
      <em>{speciality ? 'None' : 'Please select a speciality'}</em>
    </MenuItem>,
  ];

  speciality &&
    subSpecialities[speciality] &&
    subSpecialities[speciality]?.forEach(i =>
      options.push(
        <MenuItem value={i.value} key={i.key} data-testid={i.key}>
          {i.value}
        </MenuItem>,
      ),
    );

  return options;
};

export const DEFAULT_MIN_PAY_RATE_UNIT = 'Hourly';

export const MIN_PAY_RATES = {
  Hourly: ['75', '100', '125', '200', '250', '300'],
  Daily: [
    '500',
    '600',
    '700',
    '800',
    '900',
    '1000',
    '1250',
    '1500',
    '1750',
    '2000',
    '2500',
    '3000',
  ],
};
